// 客户资料
import request from '@/utils/request'
// 获取商品列表
export function clientIndex(data) {
  return request({
    url: '/baseAdmin/customer-info/index',
    method: 'post',
    data,
  })
}
// 客户资料审核-封存
export function clientStatus(data) {
  return request({
    url: '/baseAdmin/customer-info/status',
    method: 'post',
    data,
  })
}
//查询-销售区域
export function clientAreaList(data) {
  return request({
    url: '/baseAdmin/common/area-list',
    method: 'post',
    data,
  })
}
//查询-渠道
export function clientCustChannel(data) {
  return request({
    url: '/baseAdmin/common/cust-channel-option',
    method: 'post',
    data,
  })
}
// 查询-等级
export function clientCustClass(data) {
  return request({
    url: '/baseAdmin/common/cust-class-option',
    method: 'post',
    data,
  })
}
//查询-线路（获取经销商线路列表）
export function clientLineList(data) {
  return request({
    url: '/baseAdmin/common/line-list',
    method: 'post',
    data,
  })
}
//查询-业务员 （人员选框列表）
export function clientUserList(data) {
  return request({
    url: '/baseAdmin/common/user-list',
    method: 'post',
    data,
  })
}
// 创建客户 保存
export function clientCreate(data) {
  return request({
    url: '/baseAdmin/customer-info/create',
    method: 'post',
    data,
  })
}
// 编辑客户 保存
export function clientUpdate(data) {
  return request({
    url: '/baseAdmin/customer-info/update',
    method: 'post',
    data,
  })
}
//客户资料详情
export function clientDetail(data) {
  return request({
    url: '/baseAdmin/customer-info/detail',
    method: 'post',
    data,
  })
}

//添加 省市区
export function clientSelectArea(data) {
  return request({
    url: '/baseAdmin/customer-info/select-area',
    method: 'post',
    data,
  })
}
/**
 * @客户资料导出
 */
export function clientExport(data) {
  return request({
    responseType: 'blob',
    url: '/baseAdmin/customer-info/export',
    method: 'post',
    data,
  })
}

/**
 * @批量修改客户资料
 */
export function batchEdit(data) {
  return request({
    responseType: 'blob',
    url: '/baseAdmin/customer-info/batch-update',
    method: 'post',
    data,
  })
}
